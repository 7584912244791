import React from 'react';
import Sidebar from '../components/side_bar';
import Fixed_header from '../components/fixed_header';
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import { FaStarHalfAlt } from "react-icons/fa";
const Categories = () => {
  return (
    <div class="nk-app-root">
    <div className='nk-main'>
    <Sidebar/>
    <div className='nk-wrap'>
{/* ==================================================================== */}
<Fixed_header/>


{/* =================================================================================== */}
<div class="nk-content">
  <div class="container">
  <div class="nk-content-inner">
  <div class="nk-content-body">
    <div class="nk-block-head">
      <div class="nk-block-head-between flex-wrap gap g-2">
        <div class="nk-block-head-content">
          <h2 class="nk-block-title">
            Categories
          </h2>
          <nav>
            <ol class="breadcrumb breadcrumb-arrow mb-0">
              <li class="breadcrumb-item">
                <a href="#">
                  Home
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">
                  ecommerce
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Categories
              </li>
            </ol>
          </nav>
        </div>
        <div class="nk-block-head-content">
          <ul class="d-flex">
            <li>
              <a href="https://html.nioboard.themenio.com/ecommerce/add-category.html"
              class="btn btn-primary btn-md d-md-none">
                <em class="icon ni ni-plus">
                </em>
                <span>
                  Add
                </span>
              </a>
            </li>
            <li>
              <a href="https://html.nioboard.themenio.com/ecommerce/add-category.html"
              class="btn btn-primary d-none d-md-inline-flex">
                <em class="icon ni ni-plus">
                </em>
                <span>
                  Add Category
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="nk-block">
      <div class="card data-table-card">
        <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
          <div class="dataTable-top">
            <div class="dataTable-dropdown">
              <label>
                <select class="dataTable-selector">
                  <option value="5">
                    5
                  </option>
                  <option value="10" selected="">
                    10
                  </option>
                  <option value="15">
                    15
                  </option>
                  <option value="20">
                    20
                  </option>
                  <option value="25">
                    25
                  </option>
                </select>
                Per page
              </label>
            </div>
            <div class="dataTable-search">
              <input class="dataTable-input" placeholder="Search..." type="text"/>
            </div>
          </div>
          <div class="dataTable-container table-responsive">
            <table class="datatable-init table dataTable-table" data-nk-container="table-responsive">
              <thead class="table-light">
                <tr>
                  <th class="tb-col tb-col-check" data-sortable="false" style={{width: '4.0747%'}}>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value=""/>
                    </div>
                  </th>
                  <th class="tb-col" data-sortable="" style={{width: ' 65.365%'}}>
                    <a href="#" class="dataTable-sorter">
                      <span class="overline-title">
                        Category
                      </span>
                    </a>
                  </th>
                  <th class="tb-col tb-col-md" data-sortable="" style={{width: ' 9.93209%'}}>
                    <a href="#" class="dataTable-sorter">
                      <span class="overline-title">
                        type
                      </span>
                    </a>
                  </th>
                  <th class="tb-col tb-col-sm" data-sortable="" style={{width: ' 10.8659%'}}>
                    <a href="#" class="dataTable-sorter">
                      <span class="overline-title">
                        Count
                      </span>
                    </a>
                  </th>
                  <th class="tb-col tb-col-end" data-sortable="false" style={{width: ' 9.76231%'}}>
                    <span class="overline-title">
                      action
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="tb-col tb-col-check">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value=""/>
                    </div>
                  </td>
                  <td class="tb-col">
                    <div class="media-group">
                      <div class="media media-middle">
                        <img src="https://html.nioboard.themenio.com/images/product/f.jpg" alt="product"/>
                      </div>
                      <div class="media-text">
                        <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html"
                        class="title">
                          Household
                        </a>
                        <div class="text smaller d-none d-sm-block">
                          Decurate your home with our wide selection
                        </div>
                        <div class="d-sm-none">
                          <span>
                            125
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span class="badge text-bg-primary-soft">
                      Main
                    </span>
                  </td>
                  <td class="tb-col tb-col-sm">
                    <span>
                      125
                    </span>
                  </td>
                  <td class="tb-col tb-col-end">
                    <div class="dropdown">
                      <a href="#" class="btn btn-sm btn-icon btn-zoom me-n1" data-bs-toggle="dropdown">
                        <em class="icon ni ni-more-v">
                        </em>
                      </a>
                      <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                        <div class="dropdown-content py-1">
                          <ul class="link-list link-list-hover-bg-primary link-list-md">
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html">
                                <em class="icon ni ni-edit">
                                </em>
                                <span>
                                  Edit
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html">
                                <em class="icon ni ni-trash">
                                </em>
                                <span>
                                  Delete
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/categories.html">
                                <em class="icon ni ni-eye">
                                </em>
                                <span>
                                  View Category
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="tb-col tb-col-check">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value=""/>
                    </div>
                  </td>
                  <td class="tb-col">
                    <div class="media-group">
                      <div class="media media-middle">
                        <img src="https://html.nioboard.themenio.com/images/product/g.jpg" alt="product"/>
                      </div>
                      <div class="media-text">
                        <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html"
                        class="title">
                          Watches
                        </a>
                        <div class="text smaller d-none d-sm-block">
                          Discover watches you've never seen before
                        </div>
                        <div class="d-sm-none">
                          <span>
                            24
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span class="badge text-bg-primary-soft">
                      Main
                    </span>
                  </td>
                  <td class="tb-col tb-col-sm">
                    <span>
                      24
                    </span>
                  </td>
                  <td class="tb-col tb-col-end">
                    <div class="dropdown">
                      <a href="#" class="btn btn-sm btn-icon btn-zoom me-n1" data-bs-toggle="dropdown">
                        <em class="icon ni ni-more-v">
                        </em>
                      </a>
                      <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                        <div class="dropdown-content py-1">
                          <ul class="link-list link-list-hover-bg-primary link-list-md">
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html">
                                <em class="icon ni ni-edit">
                                </em>
                                <span>
                                  Edit
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html">
                                <em class="icon ni ni-trash">
                                </em>
                                <span>
                                  Delete
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/categories.html">
                                <em class="icon ni ni-eye">
                                </em>
                                <span>
                                  View Category
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="tb-col tb-col-check">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value=""/>
                    </div>
                  </td>
                  <td class="tb-col">
                    <div class="media-group">
                      <div class="media media-middle">
                        <img src="https://html.nioboard.themenio.com/images/product/h.jpg" alt="product"/>
                      </div>
                      <div class="media-text">
                        <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html"
                        class="title">
                          Headphones
                        </a>
                        <div class="text smaller d-none d-sm-block">
                          Discover a wide range of Headphone including Razer, Gamdias, Logitech
                          headphone
                        </div>
                        <div class="d-sm-none">
                          <span>
                            0
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span class="badge text-bg-info-soft">
                      Sub
                    </span>
                  </td>
                  <td class="tb-col tb-col-sm">
                    <span class="badge text-bg-danger-soft">
                      Stock out
                    </span>
                  </td>
                  <td class="tb-col tb-col-end">
                    <div class="dropdown">
                      <a href="#" class="btn btn-sm btn-icon btn-zoom me-n1" data-bs-toggle="dropdown">
                        <em class="icon ni ni-more-v">
                        </em>
                      </a>
                      <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                        <div class="dropdown-content py-1">
                          <ul class="link-list link-list-hover-bg-primary link-list-md">
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html">
                                <em class="icon ni ni-edit">
                                </em>
                                <span>
                                  Edit
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html">
                                <em class="icon ni ni-trash">
                                </em>
                                <span>
                                  Delete
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/categories.html">
                                <em class="icon ni ni-eye">
                                </em>
                                <span>
                                  View Category
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="tb-col tb-col-check">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value=""/>
                    </div>
                  </td>
                  <td class="tb-col">
                    <div class="media-group">
                      <div class="media media-middle">
                        <img src="https://html.nioboard.themenio.com/images/product/i.jpg" alt="product"/>
                      </div>
                      <div class="media-text">
                        <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html"
                        class="title">
                          Speakers
                        </a>
                        <div class="text smaller d-none d-sm-block">
                          Get your preferable Speakers at the best price
                        </div>
                        <div class="d-sm-none">
                          <span>
                            123
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span class="badge text-bg-info-soft">
                      Sub
                    </span>
                  </td>
                  <td class="tb-col tb-col-sm">
                    <span>
                      123
                    </span>
                  </td>
                  <td class="tb-col tb-col-end">
                    <div class="dropdown">
                      <a href="#" class="btn btn-sm btn-icon btn-zoom me-n1" data-bs-toggle="dropdown">
                        <em class="icon ni ni-more-v">
                        </em>
                      </a>
                      <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                        <div class="dropdown-content py-1">
                          <ul class="link-list link-list-hover-bg-primary link-list-md">
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html">
                                <em class="icon ni ni-edit">
                                </em>
                                <span>
                                  Edit
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html">
                                <em class="icon ni ni-trash">
                                </em>
                                <span>
                                  Delete
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/categories.html">
                                <em class="icon ni ni-eye">
                                </em>
                                <span>
                                  View Category
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="tb-col tb-col-check">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value=""/>
                    </div>
                  </td>
                  <td class="tb-col">
                    <div class="media-group">
                      <div class="media media-middle">
                        <img src="https://html.nioboard.themenio.com/images/product/j.jpg" alt="product"/>
                      </div>
                      <div class="media-text">
                        <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html"
                        class="title">
                          Toothbrush
                        </a>
                        <div class="text smaller d-none d-sm-block">
                          New Children Toothbrush U-Shape Baby Toothbrush With Handle Silicone
                        </div>
                        <div class="d-sm-none">
                          <span>
                            45
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span class="badge text-bg-primary-soft">
                      Main
                    </span>
                  </td>
                  <td class="tb-col tb-col-sm">
                    <span>
                      45
                    </span>
                  </td>
                  <td class="tb-col tb-col-end">
                    <div class="dropdown">
                      <a href="#" class="btn btn-sm btn-icon btn-zoom me-n1" data-bs-toggle="dropdown">
                        <em class="icon ni ni-more-v">
                        </em>
                      </a>
                      <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                        <div class="dropdown-content py-1">
                          <ul class="link-list link-list-hover-bg-primary link-list-md">
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html">
                                <em class="icon ni ni-edit">
                                </em>
                                <span>
                                  Edit
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html">
                                <em class="icon ni ni-trash">
                                </em>
                                <span>
                                  Delete
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/categories.html">
                                <em class="icon ni ni-eye">
                                </em>
                                <span>
                                  View Category
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="tb-col tb-col-check">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value=""/>
                    </div>
                  </td>
                  <td class="tb-col">
                    <div class="media-group">
                      <div class="media media-middle">
                        <img src="https://html.nioboard.themenio.com/images/product/k.jpg" alt="product"/>
                      </div>
                      <div class="media-text">
                        <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html"
                        class="title">
                          Wines
                        </a>
                        <div class="text smaller d-none d-sm-block">
                          Get the best offers on wine matched to your taste
                        </div>
                        <div class="d-sm-none">
                          <span>
                            23
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span class="badge text-bg-primary-soft">
                      Main
                    </span>
                  </td>
                  <td class="tb-col tb-col-sm">
                    <span>
                      23
                    </span>
                  </td>
                  <td class="tb-col tb-col-end">
                    <div class="dropdown">
                      <a href="#" class="btn btn-sm btn-icon btn-zoom me-n1" data-bs-toggle="dropdown">
                        <em class="icon ni ni-more-v">
                        </em>
                      </a>
                      <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                        <div class="dropdown-content py-1">
                          <ul class="link-list link-list-hover-bg-primary link-list-md">
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html">
                                <em class="icon ni ni-edit">
                                </em>
                                <span>
                                  Edit
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html">
                                <em class="icon ni ni-trash">
                                </em>
                                <span>
                                  Delete
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/categories.html">
                                <em class="icon ni ni-eye">
                                </em>
                                <span>
                                  View Category
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="tb-col tb-col-check">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value=""/>
                    </div>
                  </td>
                  <td class="tb-col">
                    <div class="media-group">
                      <div class="media media-middle">
                        <img src="https://html.nioboard.themenio.com/images/product/l.jpg" alt="product"/>
                      </div>
                      <div class="media-text">
                        <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html"
                        class="title">
                          Cameras
                        </a>
                        <div class="text smaller d-none d-sm-block">
                          Browse B&amp;H for a vast selection of Digital Cameras
                        </div>
                        <div class="d-sm-none">
                          <span>
                            12
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span class="badge text-bg-info-soft">
                      Sub
                    </span>
                  </td>
                  <td class="tb-col tb-col-sm">
                    <span>
                      12
                    </span>
                  </td>
                  <td class="tb-col tb-col-end">
                    <div class="dropdown">
                      <a href="#" class="btn btn-sm btn-icon btn-zoom me-n1" data-bs-toggle="dropdown">
                        <em class="icon ni ni-more-v">
                        </em>
                      </a>
                      <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                        <div class="dropdown-content py-1">
                          <ul class="link-list link-list-hover-bg-primary link-list-md">
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html">
                                <em class="icon ni ni-edit">
                                </em>
                                <span>
                                  Edit
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html">
                                <em class="icon ni ni-trash">
                                </em>
                                <span>
                                  Delete
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/categories.html">
                                <em class="icon ni ni-eye">
                                </em>
                                <span>
                                  View Category
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="tb-col tb-col-check">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value=""/>
                    </div>
                  </td>
                  <td class="tb-col">
                    <div class="media-group">
                      <div class="media media-middle">
                        <img src="https://html.nioboard.themenio.com/images/product/m.jpg" alt="product"/>
                      </div>
                      <div class="media-text">
                        <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html"
                        class="title">
                          T-shirts
                        </a>
                        <div class="text smaller d-none d-sm-block">
                          Explore trendy collection of printed &amp; solid t-shirts
                        </div>
                        <div class="d-sm-none">
                          <span>
                            12
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span class="badge text-bg-info-soft">
                      Sub
                    </span>
                  </td>
                  <td class="tb-col tb-col-sm">
                    <div class="d-flex align-items-center gap g-1">
                      <div class="gap-col">
                        <span class="text-warning">
                          12
                        </span>
                      </div>
                      <div class="gap-col">
                        <span class="badge text-bg-warning-soft">
                          Low
                        </span>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-end">
                    <div class="dropdown">
                      <a href="#" class="btn btn-sm btn-icon btn-zoom me-n1" data-bs-toggle="dropdown">
                        <em class="icon ni ni-more-v">
                        </em>
                      </a>
                      <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                        <div class="dropdown-content py-1">
                          <ul class="link-list link-list-hover-bg-primary link-list-md">
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html">
                                <em class="icon ni ni-edit">
                                </em>
                                <span>
                                  Edit
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html">
                                <em class="icon ni ni-trash">
                                </em>
                                <span>
                                  Delete
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/categories.html">
                                <em class="icon ni ni-eye">
                                </em>
                                <span>
                                  View Category
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="tb-col tb-col-check">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value=""/>
                    </div>
                  </td>
                  <td class="tb-col">
                    <div class="media-group">
                      <div class="media media-middle">
                        <img src="https://html.nioboard.themenio.com/images/product/n.jpg" alt="product"/>
                      </div>
                      <div class="media-text">
                        <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html"
                        class="title">
                          Health &amp; Beauty
                        </a>
                        <div class="text smaller d-none d-sm-block">
                          A great collection of Health and Beauty products online.
                        </div>
                        <div class="d-sm-none">
                          <span>
                            55
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span class="badge text-bg-primary-soft">
                      Main
                    </span>
                  </td>
                  <td class="tb-col tb-col-sm">
                    <span>
                      55
                    </span>
                  </td>
                  <td class="tb-col tb-col-end">
                    <div class="dropdown">
                      <a href="#" class="btn btn-sm btn-icon btn-zoom me-n1" data-bs-toggle="dropdown">
                        <em class="icon ni ni-more-v">
                        </em>
                      </a>
                      <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                        <div class="dropdown-content py-1">
                          <ul class="link-list link-list-hover-bg-primary link-list-md">
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html">
                                <em class="icon ni ni-edit">
                                </em>
                                <span>
                                  Edit
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html">
                                <em class="icon ni ni-trash">
                                </em>
                                <span>
                                  Delete
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/categories.html">
                                <em class="icon ni ni-eye">
                                </em>
                                <span>
                                  View Category
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                
              </tbody>
            </table>
          </div>
          <div class="dataTable-bottom">
            <div class="dataTable-info">
              Showing 1 to 10 of 15 entries
            </div>
            <nav class="dataTable-pagination">
              <ul class="dataTable-pagination-list">
                <li class="active">
                  <a href="#" data-page="1">
                    1
                  </a>
                </li>
                <li class="">
                  <a href="#" data-page="2">
                    2
                  </a>
                </li>
                <li class="pager">
                  <a href="#" data-page="2">
                    <em class="icon ni ni-chevron-right">
                    </em>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  </div>
</div>
    </div>
    </div>

    </div>
  );
};

export default Categories;
