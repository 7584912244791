import React from 'react';
import Logout from './log_out';
import { FaPowerOff } from "react-icons/fa6";


const Fixed_header = () => {
  const handleLogout = () => {
    // Remove username from localStorage
    localStorage.removeItem('username');
    // Redirect to login page
    window.location.href = '/login'; // Change '/login' to your login page URL
  };
  return (
<div className='nk-header nk-header-fixed'>
  <div className='container-fluid'>
  <div class="nk-header-wrap">
  <div class="nk-header-logo ms-n1">
    <div class="nk-sidebar-toggle">
      <button class="btn btn-sm btn-icon btn-zoom sidebar-toggle d-sm-none">
        <em class="icon ni ni-menu">
        </em>
      </button>
      <button class="btn btn-md btn-icon btn-zoom sidebar-toggle d-none d-sm-inline-flex">
        <em class="icon ni ni-menu">
        </em>
      </button>
    </div>
    <div class="nk-navbar-toggle me-2">
      
      <button class="btn btn-md btn-icon btn-zoom navbar-toggle d-none d-sm-inline-flex">
        <em class="icon ni ni-menu-right">
        </em>
      </button>
    </div>
    <a href="https://html.nioboard.themenio.com/index.html" class="logo-link">
      
    </a>
  </div>
  <nav class="nk-header-menu nk-navbar">
    <ul class="nk-nav">
      <li class="nk-nav-item has-sub">
        <a href="#" class="nk-nav-link nk-nav-toggle">
          <span class="nk-nav-text">
          
            Dashboardsss    
          </span>
        </a>
        <ul class="nk-nav-sub nk-nav-sub-lg" style={{ 
          position: 'absolute',
          inset: '0px auto auto 0px',
          margin: '0px',
          transform: 'translate3d(12px, 52px, 0px)'
        }}
        data-popper-placement="bottom-start">
          <li class="nk-nav-item">
            <a href="https://html.nioboard.themenio.com/index.html" class="nk-nav-link bg-primary-soft-hover">
              <div class="media-group flex-grow-1">
                <div class="media media-md media-middle media-border text-bg-primary-soft-outline">
                  <em class="icon ni ni-dashboard-fill">
                  </em>
                </div>
                <div class="media-text flex-grow-1">
                  <span class="title">
                    Default
                  </span>
                  <span class="sub-text d-block">
                    Website Analytics
                  </span>
                </div>
              </div>
            </a>
          </li>
          <li class="nk-nav-item">
            <a href="https://html.nioboard.themenio.com/index-ecommerce.html" class="nk-nav-link bg-secondary-soft-hover">
              <div class="media-group flex-grow-1">
                <div class="media media-md media-middle media-border text-bg-secondary-soft-outline">
                  <em class="icon ni ni-cart-fill">
                  </em>
                </div>
                <div class="media-text flex-grow-1">
                  <span class="title">
                    eCommerce
                  </span>
                  <span class="sub-text d-block">
                    Sales reports
                  </span>
                </div>
              </div>
            </a>
          </li>
          <li class="nk-nav-item">
            <a href="https://html.nioboard.themenio.com/index-project.html" class="nk-nav-link bg-success-soft-hover">
              <div class="media-group flex-grow-1">
                <div class="media media-md media-middle media-border text-bg-success-soft-outline">
                  <em class="icon ni ni-link-group">
                  </em>
                </div>
                <div class="media-text flex-grow-1">
                  <span class="title">
                    Project
                  </span>
                  <span class="sub-text d-block">
                    Tasts, graphs &amp; charts
                  </span>
                </div>
              </div>
            </a>
          </li>
          <li class="nk-nav-item">
            <a href="https://html.nioboard.themenio.com/index-marketing.html" class="nk-nav-link bg-info-soft-hover">
              <div class="media-group flex-grow-1">
                <div class="media media-md media-middle media-border text-bg-info-soft-outline">
                  <em class="icon ni ni-growth-fill">
                  </em>
                </div>
                <div class="media-text flex-grow-1">
                  <span class="title">
                    Marketing
                  </span>
                  <span class="sub-text d-block">
                    Campaings &amp; conversions
                  </span>
                </div>
              </div>
            </a>
          </li>
          <li class="nk-nav-item">
            <a href="https://html.nioboard.themenio.com/index-nft.html" class="nk-nav-link bg-danger-soft-hover">
              <div class="media-group flex-grow-1">
                <div class="media media-md media-middle media-border text-bg-danger-soft-outline">
                  <em class="icon ni ni-img-fill">
                  </em>
                </div>
                <div class="media-text flex-grow-1">
                  <span class="title">
                    NFT
                  </span>
                  <span class="sub-text d-block">
                    Sell &amp; Create your own NFTs
                  </span>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </li>
      <li class="nk-nav-item has-sub">
        <a href="#" class="nk-nav-link nk-nav-toggle">
          <span class="nk-nav-text">
            Pages
          </span>
        </a>
        <ul class="nk-nav-sub" style={{
      position: 'absolute',
      inset: '0px auto auto 0px',
      margin: '0px',
      transform: 'translate3d(12px, 52px, 0px)'
    }}
        data-popper-placement="bottom-start">
          <li class="nk-nav-item has-sub">
            <a href="#" class="nk-nav-link nk-nav-toggle">
              Applications
            </a>
            <ul class="nk-nav-sub">
              <li class="nk-nav-item">
                <a href="https://html.nioboard.themenio.com/apps/fullcalendar/calendar.html"
                class="nk-nav-link">
                  Calendar
                </a>
              </li>
              <li class="nk-nav-item has-sub">
                <a href="#" class="nk-nav-link nk-nav-toggle">
                  Kanban board
                </a>
                <ul class="nk-nav-sub">
                  <li class="nk-nav-item">
                    <a href="https://html.nioboard.themenio.com/apps/kanban/kanban-basic.html"
                    class="nk-nav-link">
                      Basic
                    </a>
                  </li>
                  <li class="nk-nav-item">
                    <a href="https://html.nioboard.themenio.com/apps/kanban/kanban-custom-board.html"
                    class="nk-nav-link">
                      Custom Board
                    </a>
                  </li>
                </ul>
              </li>
              <li class="nk-nav-item has-sub">
                <a href="#" class="nk-nav-link nk-nav-toggle">
                  User Management
                </a>
                <ul class="nk-nav-sub">
                  <li class="nk-nav-item">
                    <a href="https://html.nioboard.themenio.com/user-manage/user-list.html"
                    class="nk-nav-link">
                      Users List
                    </a>
                  </li>
                  <li class="nk-nav-item">
                    <a href="https://html.nioboard.themenio.com/user-manage/user-cards.html"
                    class="nk-nav-link">
                      Users Cards
                    </a>
                  </li>
                  <li class="nk-nav-item">
                    <a href="https://html.nioboard.themenio.com/user-manage/user-profile.html"
                    class="nk-nav-link">
                      Users Profile
                    </a>
                  </li>
                  <li class="nk-nav-item">
                    <a href="https://html.nioboard.themenio.com/user-manage/user-edit.html"
                    class="nk-nav-link">
                      Users Edit
                    </a>
                  </li>
                </ul>
              </li>
              <li class="nk-nav-item has-sub">
                <a href="#" class="nk-nav-link nk-nav-toggle">
                  eCommerce
                </a>
                <ul class="nk-nav-sub">
                  <li class="nk-nav-item">
                    <a href="https://html.nioboard.themenio.com/ecommerce/products.html" class="nk-nav-link">
                      Products
                    </a>
                  </li>
                  <li class="nk-nav-item">
                    <a href="https://html.nioboard.themenio.com/ecommerce/categories.html"
                    class="nk-nav-link">
                      Categories
                    </a>
                  </li>
                  <li class="nk-nav-item">
                    <a href="https://html.nioboard.themenio.com/ecommerce/add-product.html"
                    class="nk-nav-link">
                      Add Product
                    </a>
                  </li>
                  <li class="nk-nav-item">
                    <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html"
                    class="nk-nav-link">
                      Edit Product
                    </a>
                  </li>
                  <li class="nk-nav-item">
                    <a href="https://html.nioboard.themenio.com/ecommerce/add-category.html"
                    class="nk-nav-link">
                      Add Category
                    </a>
                  </li>
                  <li class="nk-nav-item">
                    <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html"
                    class="nk-nav-link">
                      Edit Category
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="nk-nav-item">
            <a href="https://html.nioboard.themenio.com/components/data-tables.html"
            class="nk-nav-link">
              Data tables
            </a>
          </li>
          <li class="nk-nav-item">
            <a href="https://html.nioboard.themenio.com/components/chart.html" class="nk-nav-link">
              Chart
            </a>
          </li>
          <li class="nk-nav-item">
            <a href="https://html.nioboard.themenio.com/components/sweet-alert.html"
            class="nk-nav-link">
              Sweetalert
            </a>
          </li>
          <li class="nk-nav-item">
            <a href="https://html.nioboard.themenio.com/auths/auth-register.html"
            class="nk-nav-link" target="_blank">
              Auth Register
            </a>
          </li>
          <li class="nk-nav-item">
            <a href="https://html.nioboard.themenio.com/auths/auth-login.html" class="nk-nav-link"
            target="_blank">
              Auth Login
            </a>
          </li>
          <li class="nk-nav-item">
            <a href="https://html.nioboard.themenio.com/auths/auth-reset.html" class="nk-nav-link"
            target="_blank">
              Forgot Password
            </a>
          </li>
          <li class="nk-nav-item">
            <a href="https://html.nioboard.themenio.com/error/page-404.html" class="nk-nav-link"
            target="_blank">
              Page 404
            </a>
          </li>
        </ul>
      </li>
      <li class="nk-nav-item has-sub">
        <a href="#" class="nk-nav-link nk-nav-toggle">
          <span class="nk-nav-text">
            Ui Elements
          </span>
        </a>
        <div class="nk-nav-sub">
          <div class="nk-nav-mega nk-nav-mega-lg">
            <div class="nk-nav-col">
              <h6 class="nk-nav-heading">
                Elements
              </h6>
              <ul class="link-list link-list-md link-list-hover-bg-primary">
                <li>
                  <a href="https://html.nioboard.themenio.com/components/alerts.html">
                    Alerts
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/components/badge.html">
                    Badges
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/components/breadcrumb.html">
                    Breadcrumb
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/components/buttons.html">
                    Buttons
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/components/button-group.html">
                    Button group
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/components/card.html">
                    Cards
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/components/close-button.html">
                    Close button
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/components/list-group.html">
                    List group
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/components/pagination.html">
                    Pagination
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/components/placeholders.html">
                    Placeholders
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/components/progress.html">
                    Progress
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/components/spinners.html">
                    Spinners
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/components/accordion.html">
                    Accordion
                  </a>
                </li>
              </ul>
            </div>
            <div class="nk-nav-col">
              <h6 class="nk-nav-heading">
                Components
              </h6>
              <ul class="link-list link-list-md link-list-hover-bg-primary">
                <li>
                  <a href="https://html.nioboard.themenio.com/components/carousel.html">
                    Carousel
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/components/collapse.html">
                    Collapse
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/components/dropdowns.html">
                    Dropdowns
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/components/modal.html">
                    Modal
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/components/navs-tabs.html">
                    Tabs
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/components/offcanvas.html">
                    Offcanvas
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/components/popovers.html">
                    Popovers
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/components/toasts.html">
                    Toasts
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/components/tooltips.html">
                    Tooltips
                  </a>
                </li>
              </ul>
              <h6 class="nk-nav-heading">
                Layout
              </h6>
              <ul class="link-list link-list-md link-list-hover-bg-primary">
                <li>
                  <a href="https://html.nioboard.themenio.com/layout/breakpoints.html">
                    Breakpoints
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/layout/containers.html">
                    Containers
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/layout/gutters.html">
                    Gutters
                  </a>
                </li>
              </ul>
            </div>
            <div class="nk-nav-col">
              <h6 class="nk-nav-heading">
                Utilities
              </h6>
              <ul class="link-list link-list-md link-list-hover-bg-primary">
                <li>
                  <a href="https://html.nioboard.themenio.com/utilities/background.html">
                    Background
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/utilities/borders.html">
                    Borders
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/utilities/colors.html">
                    Colors
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/utilities/flex.html">
                    Flex
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/utilities/images.html">
                    Images
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/utilities/sizing.html">
                    Sizing
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/utilities/spacing.html">
                    Spacing
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/utilities/typography.html">
                    Typography
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/utilities/tables.html">
                    Tables
                  </a>
                </li>
                <li>
                  <a href="https://html.nioboard.themenio.com/utilities/misc.html">
                    Miscellaneous
                  </a>
                </li>
              </ul>
            </div>
            <div class="nk-nav-col nk-nav-media">
              <img src="https://html.nioboard.themenio.com/images/thumb/a.jpg" alt=""
              class="rounded-3"/>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </nav>
  <div class="nk-header-tools">
    <ul class="nk-quick-nav ms-2">
      <li class="dropdown">
        
       
        <div class="dropdown-menu dropdown-menu-lg">
          <div class="dropdown-content dropdown-content-x-lg py-1">
            <div class="search-inline">
              <div class="form-control-wrap flex-grow-1">
                <input placeholder="Type Query" type="text" class="form-control-plaintext" />
              </div>
              <em class="icon icon-sm ni ni-search">
              </em>
            </div>
          </div>
          <div class="dropdown-divider m-0">
          </div>
          <div class="dropdown-content dropdown-content-x-lg py-3">
            <div class="dropdown-title pb-2">
              <h5 class="title">
                Recent searches
              </h5>
            </div>
            <ul class="dropdown-list gap gy-2">
              <li>
                <div class="media-group">
                  <div class="media media-md media-middle media-circle text-bg-light">
                    <em class="icon ni ni-clock">
                    </em>
                  </div>
                  <div class="media-text">
                    <div class="lead-text">
                      Styled Doughnut Chart
                    </div>
                    <span class="sub-text">
                      1 days ago
                    </span>
                  </div>
                  <div class="media-action media-action-end">
                    <button class="btn btn-md btn-zoom btn-icon me-n1">
                      <em class="icon ni ni-trash">
                      </em>
                    </button>
                  </div>
                </div>
              </li>
              <li>
                <div class="media-group">
                  <div class="media media-md media-middle media-circle text-bg-light">
                    <em class="icon ni ni-clock">
                    </em>
                  </div>
                  <div class="media-text">
                    <div class="lead-text">
                      Custom Select Input
                    </div>
                    <span class="sub-text">
                      07 Aug
                    </span>
                  </div>
                  <div class="media-action media-action-end">
                    <button class="btn btn-md btn-zoom btn-icon me-n1">
                      <em class="icon ni ni-trash">
                      </em>
                    </button>
                  </div>
                </div>
              </li>
              <li>
                <div class="media-group">
                  <div class="media media-md media-middle media-circle text-bg-light">
                    <img src="https://html.nioboard.themenio.com/images/avatar/a.jpg" alt=""/>
                  </div>
                  <div class="media-text">
                    <div class="lead-text">
                      Sharon Walker
                    </div>
                    <span class="sub-text">
                      Admin
                    </span>
                  </div>
                  <div class="media-action media-action-end">
                    <button class="btn btn-md btn-zoom btn-icon me-n1">
                      <em class="icon ni ni-trash">
                      </em>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </li>
      <li>
        
      <button class="btn btn-icon btn-md btn-zoom d-none d-sm-inline-flex" data-bs-toggle="offcanvas"
        data-bs-target="#notificationOffcanvas">
    <em class="icon ni ni-bell"></em>
</button>

<button onClick={handleLogout} class="btn btn-icon btn-md btn-zoom d-none d-sm-inline-flex" onclick="logout()">
    <em class="icon ni ni-signout"><FaPowerOff /></em> logout
</button>

      </li>
      <li class="dropdown">
        <a href="#" data-bs-toggle="dropdown">
          <div class="d-sm-none">
            <div class="media media-md media-circle">
              <img src="https://html.nioboard.themenio.com/images/avatar/a.jpg" alt=""
              class="img-thumbnail" />
            </div>
          </div>
          <div class="d-none d-sm-block">
            <div class="media media-circle">
              <img src="https://html.nioboard.themenio.com/images/avatar/a.jpg" alt=""
              class="img-thumbnail"/>
            </div>
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-md">
          <div class="dropdown-content dropdown-content-x-lg py-3 border-bottom border-light">
            <div class="media-group">
              <div class="media media-xl media-middle media-circle">
                <img src="https://html.nioboard.themenio.com/images/avatar/a.jpg" alt=""
                class="img-thumbnail"/>
              </div>
              <div class="media-text">
                <div class="lead-text">
                  Wesley Burland
                </div>
                <span class="sub-text">
                  Owner &amp; Founder
                </span>
              </div>
            </div>
          </div>
          <div class="dropdown-content dropdown-content-x-lg py-3 border-bottom border-light">
            <ul class="link-list">
              <li>
                <a href="https://html.nioboard.themenio.com/profile.html">
                  <em class="icon ni ni-user">
                  </em>
                  <span>
                    My Profile
                  </span>
                </a>
              </li>
              <li>
                <a href="https://html.nioboard.themenio.com/user-manage/user-cards.html">
                  <em class="icon ni ni-contact">
                  </em>
                  <span>
                    My Contacts
                  </span>
                </a>
              </li>
              <li>
                <a href="https://html.nioboard.themenio.com/profile-edit.html">
                  <em class="icon ni ni-setting-alt">
                  </em>
                  <span>
                    Account Settings
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div class="dropdown-content dropdown-content-x-lg py-3">
            <ul class="link-list">
              <li>
                <a href="#">
                  <em class="icon ni ni-signout">
                  </em>
                  <span>
                    Log Out
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>




  </div>
</div>

  )
}
export default Fixed_header;