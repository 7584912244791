import React from "react";
import { Link } from "react-router-dom";
import { LuLayoutDashboard } from "react-icons/lu";
import { PiUsersThreeLight } from "react-icons/pi";
import { BsSuitcaseLg } from "react-icons/bs";
import { useState } from 'react';
import { CiBoxList } from "react-icons/ci";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { FaUsersRays } from "react-icons/fa6";
const Sidebar = () => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive); // Toggle the isActive state
  };

  return (
    
<div className={`nk-sidebar nk-sidebar-fixed is-theme ${isActive ? 'active' : ''}`} id="sidebar">
      <div className="nk-sidebar-element nk-sidebar-head">
        <div className="nk-sidebar-brand">
          <Link
            to="/"
            className="logo-link"
          >
            <div className="logo-wrap">
              
              <img src="https://karmchari.in/pics/logo.jpeg" style={{width: '105px',
    objectFit: 'cover'}}></img>
              
            </div>
          </Link>
          <div className="nk-compact-toggle me-n1">
            <button className="btn btn-md btn-icon text-light btn-no-hover compact-toggle">
              <em className="icon off ni ni-chevrons-left"></em>
              <em className="icon on ni ni-chevrons-right"></em>
            </button>
          </div>
          <div className="nk-sidebar-toggle me-n1">
            <button className="btn btn-md btn-icon text-light btn-no-hover sidebar-toggle" id="menutoggle" onClick={handleClick}>
              <em className="icon ni ni-arrow-left"></em>
            </button>
          </div>
        </div>
      </div>
      <div className="nk-sidebar-element nk-sidebar-body">
        <div className="nk-sidebar-content">
          <div className="nk-sidebar-menu" data-simplebar="init">
            <div className="simplebar-wrapper" style={{ margin: "0px" }}>
              <div className="simplebar-height-auto-observer-wrapper">
                <div className="simplebar-height-auto-observer"></div>
              </div>
              <div className="simplebar-mask">
                <div className="simplebar-offset" style={{ right: "0px", bottom: "0px" }}>
                  <div
                    className="simplebar-content-wrapper"
                    tabIndex="0"
                    role="region"
                    aria-label="scrollable content"
                    style={{ height: "100%", overflow: "hidden scroll" }}
                  >
                   <ul className="nk-menu">
                    <li className="nk-menu-item has-sub active current-page">
                    <Link to="/" class="nk-menu-link nk-menu-toggle"><span class="nk-menu-icon"><LuLayoutDashboard /></span><span class="nk-menu-text">Dashboard </span></Link>
                    
                    </li>
                    <li class="nk-menu-heading"><h6 class="overline-title">Applications</h6></li>
                    <li class="nk-menu-item"><Link to="/users" class="nk-menu-link"><span class="nk-menu-icon"><PiUsersThreeLight /></span><span class="nk-menu-text">Users</span></Link></li>
                    <li class="nk-menu-item"><Link to="/job" class="nk-menu-link"><span class="nk-menu-icon"><BsSuitcaseLg /></span><span class="nk-menu-text">Job</span></Link></li>
                    <li class="nk-menu-item"><Link to="/categories" class="nk-menu-link"><span class="nk-menu-icon"><CiBoxList /></span><span class="nk-menu-text">Categories</span></Link></li>
                    <li class="nk-menu-item"><Link to="/invoice" class="nk-menu-link"><span class="nk-menu-icon"><LiaFileInvoiceDollarSolid /></span><span class="nk-menu-text">Invoice</span></Link></li>
                      <li class="nk-menu-item"><Link to="/staff" class="nk-menu-link"><span class="nk-menu-icon"><FaUsersRays /></span><span class="nk-menu-text">Staff</span></Link></li>

                    
                   </ul>
                  </div>
                </div>
              </div>
              <div
                className="simplebar-placeholder"
                style={{ width: "auto", height: "1216px" }}
              ></div>
            </div>
            <div
              className="simplebar-track simplebar-horizontal"
              style={{ visibility: "hidden" }}
            >
              <div
                className="simplebar-scrollbar simplebar-visible"
                style={{ width: "0px", display: "none" }}
              ></div>
            </div>
            <div
              className="simplebar-track simplebar-vertical"
              style={{ visibility: "visible" }}
            >
              <div
                className="simplebar-scrollbar simplebar-visible"
                style={{ height: "81px", transform: "translate3d(0px, 0px, 0px)", display: "block" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
