import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/login';
import HomePage from './pages/home';
import Users from './pages/user';
import Rough from './pages/rough';
import Job from './pages/job';
import Categories from './pages/categories';
import Invoice from './pages/invoice';
import Staff from './pages/staff';


function App() {
  return (
    <div>
    <BrowserRouter>
      <Routes>
        {/* Define routes */}
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/users" element={<Users/>}/>
        <Route path="/rough" element={<Rough/>}/>
        <Route path='/job' element={<Job/>}/>
        <Route path='/categories' element={<Categories/>}/>
        <Route path='/invoice' element={<Invoice/>}/>
        <Route path='/staff' element={<Staff/>}/>
      </Routes>
     
    </BrowserRouter>
    </div>
  );
}

export default App;
