import React, { useState, useEffect } from 'react';


const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [response, setResponse] = useState('');
    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        if (storedUsername) {
            // Redirect the user to the desired page
            window.location.href = '/'; // Change '/dashboard' to your desired URL
        }
    }, []);
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://app.karmchari.in/api/admin/admin_login.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({ username, password })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.text();
            setResponse(responseData);

          
        if (responseData === 'Loginnull') {
            localStorage.setItem('username', username);
            // Redirect to another page
            window.location.href = '/'; // Change '/dashboard' to your desired URL
        }
        } catch (error) {
            console.error('Error sending data to PHP API:', error);
        }
    };

    return (
        <div className="card col-6" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '3%' }}>
            <h2 style={{ textAlign: 'center', marginTop: '30px' }}>Login</h2>
            <div className="card-body">
                <div className="bio-block">
                    <form onSubmit={handleSubmit}>
                        <div className="row g-3">
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label htmlFor="username" className="form-label">Username:</label>
                                    <div className="form-control-wrap">
                                        <input className="form-control" type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password" className="form-label">Password:</label>
                                    <div className="form-control-wrap">
                                        <input className="form-control" type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <button type="submit">Submit</button>
                            </div>
                        </div>
                    </form>
                     {response && <p>{response}</p>}
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
