import React from 'react';
import Sidebar from '../components/side_bar';
import Fixed_header from '../components/fixed_header';
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import { FaStarHalfAlt } from "react-icons/fa";
const Job = () => {
  return (
    <div class="nk-app-root">
    <div className='nk-main'>
    <Sidebar/>
    <div className='nk-wrap'>
{/* ==================================================================== */}
<Fixed_header/>


{/* =================================================================================== */}
<div class="nk-content">
  <div class="container">
  <div class="nk-content-inner">
  <div class="nk-content-body">
    <div class="nk-block-head">
      <div class="nk-block-head-between flex-wrap gap g-2">
        <div class="nk-block-head-content">
          <h2 class="nk-block-title">
            Jobs
          </h2>
          <nav>
            <ol class="breadcrumb breadcrumb-arrow mb-0">
              <li class="breadcrumb-item">
                <a href="#">
                  Home
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">
                  Jobs
                </a>
              </li>
              
            </ol>
          </nav>
        </div>
        <div class="nk-block-head-content">
          
        </div>
      </div>
    </div>
    <div class="nk-block">
      <div class="card">
        <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
          <div class="dataTable-top">
            <div class="dataTable-dropdown">
              <label>
                <select class="dataTable-selector">
                  <option value="5">
                    5
                  </option>
                  <option value="10" selected="">
                    10
                  </option>
                  <option value="15">
                    15
                  </option>
                  <option value="20">
                    20
                  </option>
                  <option value="25">
                    25
                  </option>
                </select>
                Per page
              </label>
            </div>
            <div class="dataTable-search">
              <input class="dataTable-input" placeholder="Search..." type="text"/>
            </div>
          </div>
          <div class="dataTable-container table-responsive">
            <table class="datatable-init table dataTable-table" data-nk-container="table-responsive">
              <thead class="table-light">
                <tr>
                  <th class="tb-col tb-col-check" data-sortable="false" style={{width: '4.0747%'}}>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value=""/>
                    </div>
                  </th>
                  <th class="tb-col" data-sortable="" style={{width: "32.0883%"}}>
                    <a href="#" class="dataTable-sorter">
                      <span class="overline-title">
                        Job
                      </span>
                    </a>
                  </th>
                  <th class="tb-col tb-col-md" data-sortable="" style={{width: " 8.9983%"}}>
                    <a href="#" class="dataTable-sorter">
                      <span class="overline-title">
                      Recruiter
                      </span>
                    </a>
                  </th>
                  <th class="tb-col" data-sortable="" style={{width: " 11.8846%"}}>
                    <a href="#" class="dataTable-sorter">
                      <span class="overline-title">
                        count
                      </span>
                    </a>
                  </th>
                  <th class="tb-col tb-col-md" data-sortable="" style={{width: "9.59253%"}}>
                    <a href="#" class="dataTable-sorter">
                      <span class="overline-title">
                        price
                      </span>
                    </a>
                  </th>
                  <th class="tb-col tb-col-md" data-sortable="" style={{width: "13.6672%"}}>
                    <a href="#" class="dataTable-sorter">
                      <span class="overline-title">
                        rating
                      </span>
                    </a>
                  </th>
                  <th class="tb-col" data-sortable="" style={{width: "10.781%"}}>
                    <a href="#" class="dataTable-sorter">
                      <span class="overline-title">
                        Status
                      </span>
                    </a>
                  </th>
                  <th class="tb-col tb-col-end" data-sortable="false" style={{width: "8.91341%"}}>
                    <span class="overline-title">
                      action
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="tb-col tb-col-check">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="pid1"/>
                    </div>
                  </td>
                  <td class="tb-col">
                    <div class="media-group">
                      <div class="media media-md media-middle">
                        <img src="https://html.nioboard.themenio.com/images/product/a.jpg" alt="product"/>
                      </div>
                      <div class="media-text">
                        <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html"
                        class="title">
                          Pink Fitness Tracker
                        </a>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span>
                      0533009
                    </span>
                  </td>
                  <td class="tb-col">
                    <span>
                      42
                    </span>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span>
                      $126.00
                    </span>
                  </td>
                  <td class="tb-col tb-col-md">
                    <ul class="rating">
                      <li class="rating-label checked">

                      <FaStar />
                        
                      </li>
                      <li class="rating-label checked">
                      <FaStar />
                      </li>
                      <li class="rating-label checked">
                      <FaStarHalfAlt />
                      </li>
                      <li class="rating-label checked">
                      <CiStar />
                      </li>
                      <li class="rating-label checked">
                      <CiStar />
                      </li>
                    </ul>
                  </td>
                  <td class="tb-col">
                    <span class="badge text-bg-success-soft">
                      Published
                    </span>
                  </td>
                  <td class="tb-col tb-col-end">
                    <div class="dropdown">
                      <a href="#" class="btn btn-sm btn-icon btn-zoom me-n1" data-bs-toggle="dropdown">
                        <em class="icon ni ni-more-v">
                        </em>
                      </a>
                      <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                        <div class="dropdown-content py-1">
                          <ul class="link-list link-list-hover-bg-primary link-list-md">
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html">
                                <em class="icon ni ni-edit">
                                </em>
                                <span>
                                  Edit
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html">
                                <em class="icon ni ni-trash">
                                </em>
                                <span>
                                  Delete
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/products.html">
                                <em class="icon ni ni-eye">
                                </em>
                                <span>
                                  View Details
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="tb-col tb-col-check">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value=""/>
                    </div>
                  </td>
                  <td class="tb-col">
                    <div class="media-group">
                      <div class="media media-md media-middle">
                        <img src="https://html.nioboard.themenio.com/images/product/b.jpg" alt="product"/>
                      </div>
                      <div class="media-text">
                        <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html"
                        class="title">
                          Pool Party Drink Holder
                        </a>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span>
                      0253800
                    </span>
                  </td>
                  <td class="tb-col">
                    <span>
                      12
                    </span>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span>
                      $35.99
                    </span>
                  </td>
                  <td class="tb-col tb-col-md">
                    <ul class="rating">
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label">
                        <em class="icon ni ni-star">
                        </em>
                      </li>
                    </ul>
                  </td>
                  <td class="tb-col">
                    <span class="badge text-bg-info-soft">
                      Scheduled
                    </span>
                  </td>
                  <td class="tb-col tb-col-end">
                    <div class="dropdown">
                      <a href="#" class="btn btn-sm btn-icon btn-zoom me-n1" data-bs-toggle="dropdown">
                        <em class="icon ni ni-more-v">
                        </em>
                      </a>
                      <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                        <div class="dropdown-content py-1">
                          <ul class="link-list link-list-hover-bg-primary link-list-md">
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html">
                                <em class="icon ni ni-edit">
                                </em>
                                <span>
                                  Edit
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html">
                                <em class="icon ni ni-trash">
                                </em>
                                <span>
                                  Delete
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/products.html">
                                <em class="icon ni ni-eye">
                                </em>
                                <span>
                                  View Details
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="tb-col tb-col-check">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value=""/>
                    </div>
                  </td>
                  <td class="tb-col">
                    <div class="media-group">
                      <div class="media media-md media-middle">
                        <img src="https://html.nioboard.themenio.com/images/product/c.jpg" alt="product"/>
                      </div>
                      <div class="media-text">
                        <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html"
                        class="title">
                          AliExpress Fitness Trackers
                        </a>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span>
                      0253804
                    </span>
                  </td>
                  <td class="tb-col">
                    <span>
                      15
                    </span>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span>
                      $135.99
                    </span>
                  </td>
                  <td class="tb-col tb-col-md">
                    <ul class="rating">
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label">
                        <em class="icon ni ni-star">
                        </em>
                      </li>
                      <li class="rating-label">
                        <em class="icon ni ni-star">
                        </em>
                      </li>
                    </ul>
                  </td>
                  <td class="tb-col">
                    <span class="badge text-bg-danger-soft">
                      Inactive
                    </span>
                  </td>
                  <td class="tb-col tb-col-end">
                    <div class="dropdown">
                      <a href="#" class="btn btn-sm btn-icon btn-zoom me-n1" data-bs-toggle="dropdown">
                        <em class="icon ni ni-more-v">
                        </em>
                      </a>
                      <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                        <div class="dropdown-content py-1">
                          <ul class="link-list link-list-hover-bg-primary link-list-md">
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html">
                                <em class="icon ni ni-edit">
                                </em>
                                <span>
                                  Edit
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html">
                                <em class="icon ni ni-trash">
                                </em>
                                <span>
                                  Delete
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/products.html">
                                <em class="icon ni ni-eye">
                                </em>
                                <span>
                                  View Details
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="tb-col tb-col-check">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value=""/>
                    </div>
                  </td>
                  <td class="tb-col">
                    <div class="media-group">
                      <div class="media media-md media-middle">
                        <img src="https://html.nioboard.themenio.com/images/product/d.jpg" alt="product"/>
                      </div>
                      <div class="media-text">
                        <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html"
                        class="title">
                          iPhone 7 Headphones
                        </a>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span>
                      0253807
                    </span>
                  </td>
                  <td class="tb-col">
                    <span>
                      13
                    </span>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span>
                      $13.99
                    </span>
                  </td>
                  <td class="tb-col tb-col-md">
                    <ul class="rating">
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label">
                        <em class="icon ni ni-star">
                        </em>
                      </li>
                      <li class="rating-label">
                        <em class="icon ni ni-star">
                        </em>
                      </li>
                    </ul>
                  </td>
                  <td class="tb-col">
                    <span class="badge text-bg-danger-soft">
                      Inactive
                    </span>
                  </td>
                  <td class="tb-col tb-col-end">
                    <div class="dropdown">
                      <a href="#" class="btn btn-sm btn-icon btn-zoom me-n1" data-bs-toggle="dropdown">
                        <em class="icon ni ni-more-v">
                        </em>
                      </a>
                      <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                        <div class="dropdown-content py-1">
                          <ul class="link-list link-list-hover-bg-primary link-list-md">
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html">
                                <em class="icon ni ni-edit">
                                </em>
                                <span>
                                  Edit
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html">
                                <em class="icon ni ni-trash">
                                </em>
                                <span>
                                  Delete
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/products.html">
                                <em class="icon ni ni-eye">
                                </em>
                                <span>
                                  View Details
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="tb-col tb-col-check">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value=""/>
                    </div>
                  </td>
                  <td class="tb-col">
                    <div class="media-group">
                      <div class="media media-md media-middle">
                        <img src="https://html.nioboard.themenio.com/images/product/e.jpg" alt="product"/>
                      </div>
                      <div class="media-text">
                        <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html"
                        class="title">
                          Purple Blue Gradient iPhone Case
                        </a>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span>
                      0253808
                    </span>
                  </td>
                  <td class="tb-col">
                    <div class="d-flex align-items-center gap g-1">
                      <div class="gap-col">
                        <span class="fw-semibold text-danger">
                          0
                        </span>
                      </div>
                      <div class="gap-col">
                        <span class="badge text-bg-danger-soft">
                          Sold out
                        </span>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span>
                      $55.99
                    </span>
                  </td>
                  <td class="tb-col tb-col-md">
                    <ul class="rating">
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label">
                        <em class="icon ni ni-star">
                        </em>
                      </li>
                    </ul>
                  </td>
                  <td class="tb-col">
                    <span class="badge text-bg-success-soft">
                      Published
                    </span>
                  </td>
                  <td class="tb-col tb-col-end">
                    <div class="dropdown">
                      <a href="#" class="btn btn-sm btn-icon btn-zoom me-n1" data-bs-toggle="dropdown">
                        <em class="icon ni ni-more-v">
                        </em>
                      </a>
                      <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                        <div class="dropdown-content py-1">
                          <ul class="link-list link-list-hover-bg-primary link-list-md">
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html">
                                <em class="icon ni ni-edit">
                                </em>
                                <span>
                                  Edit
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html">
                                <em class="icon ni ni-trash">
                                </em>
                                <span>
                                  Delete
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/products.html">
                                <em class="icon ni ni-eye">
                                </em>
                                <span>
                                  View Details
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="tb-col tb-col-check">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value=""/>
                    </div>
                  </td>
                  <td class="tb-col">
                    <div class="media-group">
                      <div class="media media-md media-middle">
                        <img src="https://html.nioboard.themenio.com/images/product/a.jpg" alt="product"/>
                      </div>
                      <div class="media-text">
                        <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html"
                        class="title">
                          Wireless Waterproof Speaker
                        </a>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span>
                      0253808
                    </span>
                  </td>
                  <td class="tb-col">
                    <span>
                      55
                    </span>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span>
                      $135.99
                    </span>
                  </td>
                  <td class="tb-col tb-col-md">
                    <ul class="rating">
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label">
                        <em class="icon ni ni-star">
                        </em>
                      </li>
                    </ul>
                  </td>
                  <td class="tb-col">
                    <span class="badge text-bg-success-soft">
                      Published
                    </span>
                  </td>
                  <td class="tb-col tb-col-end">
                    <div class="dropdown">
                      <a href="#" class="btn btn-sm btn-icon btn-zoom me-n1" data-bs-toggle="dropdown">
                        <em class="icon ni ni-more-v">
                        </em>
                      </a>
                      <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                        <div class="dropdown-content py-1">
                          <ul class="link-list link-list-hover-bg-primary link-list-md">
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html">
                                <em class="icon ni ni-edit">
                                </em>
                                <span>
                                  Edit
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html">
                                <em class="icon ni ni-trash">
                                </em>
                                <span>
                                  Delete
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/products.html">
                                <em class="icon ni ni-eye">
                                </em>
                                <span>
                                  View Details
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="tb-col tb-col-check">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value=""/>
                    </div>
                  </td>
                  <td class="tb-col">
                    <div class="media-group">
                      <div class="media media-md media-middle">
                        <img src="https://html.nioboard.themenio.com/images/product/a.jpg" alt="product"/>
                      </div>
                      <div class="media-text">
                        <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html"
                        class="title">
                          Black Headphones
                        </a>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span>
                      0533009
                    </span>
                  </td>
                  <td class="tb-col">
                    <span>
                      42
                    </span>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span>
                      $126.00
                    </span>
                  </td>
                  <td class="tb-col tb-col-md">
                    <ul class="rating">
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                    </ul>
                  </td>
                  <td class="tb-col">
                    <span class="badge text-bg-success-soft">
                      Published
                    </span>
                  </td>
                  <td class="tb-col tb-col-end">
                    <div class="dropdown">
                      <a href="#" class="btn btn-sm btn-icon btn-zoom me-n1" data-bs-toggle="dropdown">
                        <em class="icon ni ni-more-v">
                        </em>
                      </a>
                      <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                        <div class="dropdown-content py-1">
                          <ul class="link-list link-list-hover-bg-primary link-list-md">
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html">
                                <em class="icon ni ni-edit">
                                </em>
                                <span>
                                  Edit
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html">
                                <em class="icon ni ni-trash">
                                </em>
                                <span>
                                  Delete
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/products.html">
                                <em class="icon ni ni-eye">
                                </em>
                                <span>
                                  View Details
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="tb-col tb-col-check">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value=""/>
                    </div>
                  </td>
                  <td class="tb-col">
                    <div class="media-group">
                      <div class="media media-md media-middle">
                        <img src="https://html.nioboard.themenio.com/images/product/b.jpg" alt="product"/>
                      </div>
                      <div class="media-text">
                        <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html"
                        class="title">
                          Flared Shift Dress
                        </a>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span>
                      0253800
                    </span>
                  </td>
                  <td class="tb-col">
                    <div class="d-flex align-items-center gap g-1">
                      <div class="gap-col">
                        <span class="fw-semibold text-warning">
                          4
                        </span>
                      </div>
                      <div class="gap-col">
                        <span class="badge text-bg-warning-soft">
                          Low stock
                        </span>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span>
                      $35.99
                    </span>
                  </td>
                  <td class="tb-col tb-col-md">
                    <ul class="rating">
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label">
                        <em class="icon ni ni-star">
                        </em>
                      </li>
                    </ul>
                  </td>
                  <td class="tb-col">
                    <span class="badge text-bg-info-soft">
                      Scheduled
                    </span>
                  </td>
                  <td class="tb-col tb-col-end">
                    <div class="dropdown">
                      <a href="#" class="btn btn-sm btn-icon btn-zoom me-n1" data-bs-toggle="dropdown">
                        <em class="icon ni ni-more-v">
                        </em>
                      </a>
                      <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                        <div class="dropdown-content py-1">
                          <ul class="link-list link-list-hover-bg-primary link-list-md">
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html">
                                <em class="icon ni ni-edit">
                                </em>
                                <span>
                                  Edit
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html">
                                <em class="icon ni ni-trash">
                                </em>
                                <span>
                                  Delete
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/products.html">
                                <em class="icon ni ni-eye">
                                </em>
                                <span>
                                  View Details
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="tb-col tb-col-check">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value=""/>
                    </div>
                  </td>
                  <td class="tb-col">
                    <div class="media-group">
                      <div class="media media-md media-middle">
                        <img src="https://html.nioboard.themenio.com/images/product/c.jpg" alt="product"/>
                      </div>
                      <div class="media-text">
                        <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html"
                        class="title">
                          Nio Extended Camera
                        </a>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span>
                      0253804
                    </span>
                  </td>
                  <td class="tb-col">
                    <span>
                      15
                    </span>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span>
                      $135.99
                    </span>
                  </td>
                  <td class="tb-col tb-col-md">
                    <ul class="rating">
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label">
                        <em class="icon ni ni-star">
                        </em>
                      </li>
                      <li class="rating-label">
                        <em class="icon ni ni-star">
                        </em>
                      </li>
                    </ul>
                  </td>
                  <td class="tb-col">
                    <span class="badge text-bg-danger-soft">
                      Inactive
                    </span>
                  </td>
                  <td class="tb-col tb-col-end">
                    <div class="dropdown">
                      <a href="#" class="btn btn-sm btn-icon btn-zoom me-n1" data-bs-toggle="dropdown">
                        <em class="icon ni ni-more-v">
                        </em>
                      </a>
                      <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                        <div class="dropdown-content py-1">
                          <ul class="link-list link-list-hover-bg-primary link-list-md">
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html">
                                <em class="icon ni ni-edit">
                                </em>
                                <span>
                                  Edit
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html">
                                <em class="icon ni ni-trash">
                                </em>
                                <span>
                                  Delete
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/products.html">
                                <em class="icon ni ni-eye">
                                </em>
                                <span>
                                  View Details
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="tb-col tb-col-check">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value=""/>
                    </div>
                  </td>
                  <td class="tb-col">
                    <div class="media-group">
                      <div class="media media-md media-middle">
                        <img src="https://html.nioboard.themenio.com/images/product/d.jpg" alt="product"/>
                      </div>
                      <div class="media-text">
                        <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html"
                        class="title">
                          Battery Charger
                        </a>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span>
                      0253807
                    </span>
                  </td>
                  <td class="tb-col">
                    <span>
                      13
                    </span>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span>
                      $13.99
                    </span>
                  </td>
                  <td class="tb-col tb-col-md">
                    <ul class="rating">
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label checked">
                        <em class="icon ni ni-star-fill">
                        </em>
                      </li>
                      <li class="rating-label">
                        <em class="icon ni ni-star">
                        </em>
                      </li>
                      <li class="rating-label">
                        <em class="icon ni ni-star">
                        </em>
                      </li>
                    </ul>
                  </td>
                  <td class="tb-col">
                    <span class="badge text-bg-danger-soft">
                      Inactive
                    </span>
                  </td>
                  <td class="tb-col tb-col-end">
                    <div class="dropdown">
                      <a href="#" class="btn btn-sm btn-icon btn-zoom me-n1" data-bs-toggle="dropdown">
                        <em class="icon ni ni-more-v">
                        </em>
                      </a>
                      <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                        <div class="dropdown-content py-1">
                          <ul class="link-list link-list-hover-bg-primary link-list-md">
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html">
                                <em class="icon ni ni-edit">
                                </em>
                                <span>
                                  Edit
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-product.html">
                                <em class="icon ni ni-trash">
                                </em>
                                <span>
                                  Delete
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/products.html">
                                <em class="icon ni ni-eye">
                                </em>
                                <span>
                                  View Details
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="dataTable-bottom">
            <div class="dataTable-info">
              Showing 1 to 10 of 16 entries
            </div>
            <nav class="dataTable-pagination">
              <ul class="dataTable-pagination-list">
                <li class="active">
                  <a href="#" data-page="1">
                    1
                  </a>
                </li>
                <li class="">
                  <a href="#" data-page="2">
                    2
                  </a>
                </li>
                <li class="pager">
                  <a href="#" data-page="2">
                    <em class="icon ni ni-chevron-right">
                    </em>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  </div>
</div>
    </div>
    </div>

    </div>
  );
};

export default Job;
